import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactOne from "../elements/contact/ContactOne";
import ServiceList from "../elements/service/ServiceList";
import WorksList from "../elements/works/WorksList";
import {
    Label
} from 'reactstrap';

import {
    Row, Col, Card, CardBody, Progress, UncontrolledTooltip, Button, Alert, FormGroup,
} from 'reactstrap';
import Faqs from '../elements/faq/FaqList';

const SlideList = [
    {
        textPosition: 'text-left',
        description: '',
        buttonText: 'Download App',
        buttonLink: process.env.REACT_APP_APP_STORE_URL
    }
]
const PortfolioLanding = () => {
    return (
        <div className="active-dark bg_color--ugs">
            <Helmet pageTitle="Home" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />

            {/* Start Slider Area   */}
            <div className="top-inset-main">
                <div id="home" className="bg_intro">
                    <br /><br /><br /><br /><br />
                    <div className="slider-wrapper">
                        {/* Start Single Slide */}
                        {SlideList.map((value, index) => (
                            <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className='align-vertical align-items-center'>
                                                <p className="header-title">Sharing a payment has never been easier.</p>
                                                <p className="header-title">Tap, Tap, Pay.</p>
                                                <br />

                                                <div className='align-horizontal'>
                                                    <div className="header-btn">
                                                        <a href={process.env.REACT_APP_APP_STORE_URL}>
                                                            <img className="" src="/assets/images/gp_images/gp_button_appstore.png" alt="GROUPAY LOGO" />
                                                        </a>
                                                        <a href={process.env.REACT_APP_GOOGLE_PLAY_URL}>
                                                            <img className="" src="/assets/images/gp_images/gp_button_googleplay.png" alt="GROUPAY LOGO" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <br />
                                                <br />
                                                <img style={{ maxWidth: '850px', objectFit: 'cover', height: 'auto', width: '100%' }} src="/assets/images/gp_images/gp_intro_mockup.png" alt="GROUPAY LOGO" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* End Single Slide */}
                    </div>
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start Our Mission Area */}
            <Card id="mission" className="">
                <CardBody>
                    <div className="service-area creative-service-wrapper pb--120" >
                        <div className="container">
                            <div className="row">
                                <div className="flex justify-content-center col-lg-12 align-items-center">
                                    <div className="section-title align-horizontal text-center mt-50 mb_sm--0">
                                        <p className="section-title">Why Groupay.cc</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <ServiceList item="4" column="col-lg-6 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>


                </CardBody>
            </Card>
            {/* End Our Mission Area */}
            <div id="none" className="fix top-inset-main">
                <div className="service-area creative-service-wrapper pb--80 card_color--ugs bg_why_bottom" >
                    <div className="container">
                        <div className="col-lg-12 padding-text-part">
                            <p className="why_bottom text-left-important">Overall, Groupay.cc offers a simple, flexible, and secure way to split expenses with others. It is the ideal solution for existing splitting restraints, and makes sharing payments easier and more efficient.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Start How Our App Works Area  */}
            <div id="howitworks" className="fix top-inset-main" style={{ marginTop: '-10px' }}>
                <div className="service-area creative-service-wrapper pb--120 card_color--ugs">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30 mb_sm--0">
                                    <br /><br />
                                    <div className="flex justify-content-center col-lg-12 align-items-center">
                                        <div className="section-title align-horizontal text-center mb--30 mb_sm--0">
                                            <p className="section-title-regular mr-2"></p>
                                            <p className="section-title">How It Works</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <WorksList item="4" column="col-lg-12 col-md-12 col-sm-12 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End How Our App Works Area  */}

            {/* Start Downloads Area  */}
            <div id="download" className="fix top-inset-main" style={{ marginTop: "-10px" }}>
                <div className="service-area creative-service-wrapper pb--80 card_color--ugs " >
                    <div className="container card_color--gradient">
                        <div className="col-lg-12 ">
                            <div className="section-title text-center mb_sm--0 d-flex-column-20 ">
                                <br /><br />
                                <div className="flex justify-content-center col-lg-12 align-items-center">
                                    <div className="section-title align-horizontal text-center mb_sm--0">
                                        <div>
                                            <p className="section-title text-color-1E9963">Download Groupay.cc Today And Start</p>
                                            <p className='section-title text-color-1E9963'> Simplifying Your Shared Expenses.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='align-horizontal align-items-center justify-content-center'>
                                    <a href={process.env.REACT_APP_APP_STORE_URL}>
                                        <img className="" src="/assets/images/gp_images/gp_button_appstore.png" alt="GROUPAY LOGO" />
                                    </a>
                                    <a href={process.env.REACT_APP_GOOGLE_PLAY_URL}>
                                        <img className="" src="/assets/images/gp_images/gp_button_googleplay.png" alt="GROUPAY LOGO" />
                                    </a>
                                </div>

                                <div className="flex justify-content-center col-lg-12 align-items-center">
                                    <img className="" height={600} src="/assets/images/gp_images/gp_download_mockup.png" alt="GROUPAY LOGO" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Dowmloads Area  */}


            {/* Start FAQs Area  */}

            {/* End FAQs Area  */}


            {/* Start Contact Area */}
            <div id="contact" className="fix" style={{ marginTop: "-10px" }}>
                <div className="rn-contact-area pb--120 custom_back_ground">
                    <ContactOne contactImages="/assets/images/about/about-13.jpg" contactTitle="Hire Me." />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo homeLink="/" logo="symbol-dark" color="color-black" />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </div>
    )
}

export default PortfolioLanding;
