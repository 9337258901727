import React from 'react';
import {
    FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn, FaSpotify,
    FaSoundcloud, FaDiscord, FaYoutube, FaFacebook
} from "react-icons/fa";
import { isMobile } from 'react-device-detect';
import { Label } from 'reactstrap';

//Social buttons actions are disabled due to social pages are not available yet, and must be activated at footer after filling the real social pages links
const SocialShare = [
    { Social: <FaFacebook />, link: 'https://facebook.com' },
    { Social: <FaTwitter />, link: 'https://twitter.com/' },
    { Social: <FaInstagram />, link: 'https://www.instagram.com/groupay/' },
]

const FooterTwo = (props) => {
    let logoUrl = isMobile ? <img className="logo-text-mobile" src="/assets/images/gp_images/gp_logo.png" alt="GROUPAY LOGO" /> :
        <img className="logo-text" src="/assets/images/gp_images/gp_logo.png" alt="GROUPAY LOGO" />

    return (
        <div className="footer-style-2 ptb--30 bg_color--6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="col align-items-center justify-content-between">
                    <div className="inner text-center">
                        <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                            <a href={props.homeLink}>
                                <div className="d-flex align-items-center gap-5">{logoUrl} <Label className="font-size-32 text-color-white mb-0 font-bold cursor-pointer">Groupay.cc</Label></div>
                            </a>
                        </ul>
                    </div>

                    <nav className="mainmenunav d-lg-block text-center">
                        <ul className="mainmenu">
                            <li><a href="#home">Home</a></li>
                            <li><a href="#mission">Why Groupay.cc</a></li>
                            <li><a href="#howitworks">How It Works</a></li>
                            <li><a href="#faqs">FAQs</a></li>
                            <li><a href="#contact">Contact Us</a></li>
                            <li><a href="#download" style={{color: "#2DA885"}}>DOWNLOAD</a></li>
                        </ul>
                    </nav>
                    <div className="inner text-center">
                        <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                            {SocialShare.map((val, i) => (
                                //Disable action until the real social page is created
                                <li key={i}><a /*href={`${val.link}`}*/>{val.Social}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;